import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';

const LatLongInput = ({ 
  initialLat, 
  initialLong, 
  onLatChange, 
  onLongChange, 
  citySelected,
  t 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [latDegrees, setLatDegrees] = useState('');
  const [longDegrees, setLongDegrees] = useState('');
  const [latDirection, setLatDirection] = useState('N');
  const [longDirection, setLongDirection] = useState('E');

  useEffect(() => {
    if (initialLat && initialLong) {
      const lat = Math.abs(parseFloat(initialLat));
      const long = Math.abs(parseFloat(initialLong));
      setLatDegrees(lat.toFixed(4));
      setLongDegrees(long.toFixed(4));
      setLatDirection(initialLat >= 0 ? 'N' : 'S');
      setLongDirection(initialLong >= 0 ? 'E' : 'W');
    }
  }, [initialLat, initialLong]);

  const handleStepChange = (field, increment) => {
    const step = 0.0001;
    if (field === 'lat') {
      const newValue = parseFloat(latDegrees) + (increment ? step : -step);
      setLatDegrees(newValue.toFixed(4));
      onLatChange(latDirection === 'N' ? newValue : -newValue);
    } else {
      const newValue = parseFloat(longDegrees) + (increment ? step : -step);
      setLongDegrees(newValue.toFixed(4));
      onLongChange(longDirection === 'E' ? newValue : -newValue);
    }
  };

  const handleDirectionChange = (field, value) => {
    if (field === 'lat') {
      setLatDirection(value);
      onLatChange(value === 'N' ? parseFloat(latDegrees) : -parseFloat(latDegrees));
    } else {
      setLongDirection(value);
      onLongChange(value === 'E' ? parseFloat(longDegrees) : -parseFloat(longDegrees));
    }
  };

  if (!citySelected) {
    return null;
  }

  return (
    <div className="d-flex flex-row align-items-center gap-3">
      {/* Latitude Input */}
      <div className="form-group">
        <label className="form-label">{t("Latitude")}:</label>
        <div className="d-flex align-items-center">
          <div className="position-relative" style={{ width: '120px' }}>
            <input
              type="text"
              className="form-control"
              value={latDegrees}
              disabled={!isEditing}
              onChange={(e) => {
                setLatDegrees(e.target.value);
                onLatChange(latDirection === 'N' ? parseFloat(e.target.value) : -parseFloat(e.target.value));
              }}
              style={{ paddingRight: '20px' }}
            />
            {isEditing && (
              <div 
                className="position-absolute"
                style={{
                  right: '2px',
                  top: '1px',
                  bottom: '1px',
                  width: '18px',
                  background: '#fff',
                  borderLeft: '1px solid #dee2e6'
                }}
              >
                <button 
                  className="btn p-0 position-absolute"
                  style={{ top: '-4px', right: '0', left: '0', height: '50%',background: 'none' }}
                  onClick={() => handleStepChange('lat', true)}
                >
                  <span style={{ fontSize: '8px' }}>▲</span>
                </button>
                <button 
                  className="btn p-0 position-absolute"
                  style={{ bottom: '5px', right: '0', left: '0', height: '50%',background: 'none'  }}
                  onClick={() => handleStepChange('lat', false)}
                >
                  <span style={{ fontSize: '8px' }}>▼</span>
                </button>
              </div>
            )}
          </div>
          <select
            className="form-select"
            value={latDirection}
            onChange={(e) => handleDirectionChange('lat', e.target.value)}
            disabled={!isEditing}
            style={{ width: '45px', marginLeft: '4px',marginRight: '4px' }}
          >
            <option value="N">N</option>
            <option value="S">S</option>
          </select>
        </div>
      </div>

      {/* Longitude Input */}
      <div className="form-group">
        <label className="form-label">{t("Longitude")}:</label>
        <div className="d-flex align-items-center">
          <div className="position-relative" style={{ width: '120px' }}>
            <input
              type="text"
              className="form-control"
              value={longDegrees}
              disabled={!isEditing}
              onChange={(e) => {
                setLongDegrees(e.target.value);
                onLongChange(longDirection === 'E' ? parseFloat(e.target.value) : -parseFloat(e.target.value));
              }}
              style={{ paddingRight: '20px' }}
            />
            {isEditing && (
              <div 
                className="position-absolute"
                style={{
                  right: '2px',
                  top: '1px',
                  bottom: '1px',
                  width: '18px',
                  background: '#fff',
                  borderLeft: '1px solid #dee2e6'
                }}
              >
                <button 
                  className="btn p-0 position-absolute"
                  style={{ top: '-4px', right: '0', left: '0', height: '50%',backgroundColor:'none' }}
                  onClick={() => handleStepChange('long', true)}
                >
                  <span style={{ fontSize: '8px' }}>▲</span>
                </button>
                <button 
                  className="btn p-0 position-absolute"
                  style={{ bottom: '5px', right: '0', left: '0', height: '50%',backgroundColor:'none'  }}
                  onClick={() => handleStepChange('long', false)}
                >
                  <span style={{ fontSize: '8px' }}>▼</span>
                </button>
              </div>
            )}
          </div>
          <select
            className="form-select"
            value={longDirection}
            onChange={(e) => handleDirectionChange('long', e.target.value)}
            disabled={!isEditing}
            style={{ width: '45px', marginLeft: '4px',marginRight: '4px' }}
          >
            <option value="E">E</option>
            <option value="W">W</option>
          </select>
        </div>
      </div>

      {/* Edit Button */}
      <button
        className="btn p-0"
        onClick={() => setIsEditing(!isEditing)}
        style={{ marginTop: '20px',color:'#345E9E' }}
      >
        <FiEdit size={16} />
      </button>
    </div>
  );
};

export default LatLongInput;