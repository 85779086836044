import React, { Fragment, useContext, useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Autocomplete from "../Autocomplete/autocomplete";
import CountryAutocomplete from "../Autocomplete/CountryAutocomplete";
import { GlobalContext } from "../../../mycontext";
import "../sideform.css";
import Modal from "react-bootstrap/Modal";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../../../API/Api";
import { refreshTokensetup } from "../../GoogleLogin/refreshToken";
import { useTranslation } from "react-i18next";
import Login from "../../GoogleLogin/login";
import { IMaskInput } from "react-imask";
import { endsWith } from "lodash";
import { ExitToApp } from "@material-ui/icons";
import LatLongInput from "./latLong";

function pad(n) {
  return n < 10 ? "0" + n : n;
}

const genderMapper = {
  Male: 0,
  Female: 1,
  पुरुष: 0,
  स्त्री: 1,
};

function timeFormatter(name, time) {
  var ampm = time.slice(time.length - 2, time.length);
  var SplitTime = time.slice(0, time.length - 2).split(":");
  var h = parseInt(SplitTime[0]);
  var min = parseInt(SplitTime[1]);
  var sec = parseInt(SplitTime[2].trim());
  if (ampm === "AM" && h === 12) {
    h = 0;
  } else if (ampm === "PM" && h === 12) {
    h = 12;
  } else if (ampm === "PM") {
    h = 12 + h;
  }
  if (name === "hour") {
    return h.toString();
  }
  if (name === "min") {
    return min.toString();
  }
  if (name === "sec") {
    return sec.toString();
  }
}

var dayArray = Array.from({ length: 31 }, (_, i) => i + 1);
var hourArray = Array.from({ length: 24 }, (_, i) => i + 1);
hourArray.pop();
hourArray.unshift(0);
var minSecArray = Array.from({ length: 60 }, (_, i) => i + 1);
minSecArray.pop();
minSecArray.unshift(0);

function leapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

var yearArray = [];
for (var i = 1900; i <= 2200; i++) {
  yearArray.push(i);
}

const processDates = (date_string) => {
  let parts_of_date = date_string.split("-");

  let output = new Date(
    +parts_of_date[2],
    parts_of_date[1] - 1,
    +parts_of_date[0]
  );
  return output;
};

const date = new Date();
const time =
  pad(date.getHours()) +
  ":" +
  pad(date.getMinutes()) +
  ":" +
  pad(date.getSeconds());

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "#6c757d",
    },
    "& .MuiInputBase-input": {
      height: "1.5em",
    },
    "& .MuiInput-underline": {
      borderBottom: "0px",
    },
  },
});

const HoroscopeForm = (props) => {
  const context = useContext(GlobalContext);

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  var [placeError, setPlaceError] = useState(null);
  var [horoscopeDay, setHoroscopeDay] = useState(null);
  var [horoscopeMonth, setHoroscopeMonth] = useState(null);
  var [horoscopeYear, setHoroscopeYear] = useState(null);
  var [horoscopeDate, setHoroscopeDate] = useState(null);
  var [horoscopesDOB, setHoroscopesDOB] = useState(null);
  var [horoscopesTime, setHoroscopesTime] = useState(null);
  var [horoscopesLat, setHoroscopesLat] = useState(null);
  var [horoscopesLong, setHoroscopesLong] = useState(null);

  var [horoscopeHour, setHoroscopeHour] = useState(null);
  var [horoscopeMin, setHoroscopeMin] = useState(null);
  var [horoscopeSec, setHoroscopeSec] = useState(null);
  var [horoscopeCountry, setHoroscopeCountry] = useState(null);
  var [horoscopeCityState, setHoroscopeCityState] = useState(null);
  var [horoscopeGender, setHoroscopeGender] = useState(null);
  let [horoscopeName, setHoroscopeName] = useState(null);
  var [horoscopeTime, setHoroscopeTime] = useState(time);
  var [horoscopePlaceError, setHoroscopePlaceError] = useState(null);
  const [show, setShow] = useState(false);
  const [optionclick, setoptionclick] = useState("");
  const [islogin, setislogin] = useState(false);
  const [page, setPage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    setPage(val);
    setShow(true);
  };
  const horoscopeDateChange = (date) => {
    setHoroscopeDate(date);
  };

  const horoscopePlaceErrorChange = (error) => {
    setHoroscopePlaceError(error);
  };
  const horoscopeCountryChange = (country) => {
    setHoroscopeCountry(country);
  };

  const horoscopeCityStateChange = (citystate) => {
    setHoroscopeCityState(citystate);
  };

  const horoscopeTimeChange = (place) => {
    setHoroscopeTime(place.target.value);
  };

  const horoscopeNameChange = (name) => {
    setHoroscopeName(name.target.value);
  };

  const horoscopeLatChange = (lat) => {
    setHoroscopesLat(lat);
  };

  const horoscopeLongChange = (long) => {
    setHoroscopesLong(long);
  };

  const horoscopeMonthChange = (month) => {
    setHoroscopeMonth(month.target.value);
  };
  const horoscopeYearChange = (year) => {
    setHoroscopeYear(year.target.value);
  };
  const horoscopeDayChange = (day) => {
    setHoroscopeDay(day.target.value);
  };

  const horoscopeHourChange = (hour) => {
    setHoroscopeHour(hour.target.value);
  };
  const horoscopeMinChange = (min) => {
    setHoroscopeMin(min.target.value);
  };
  const horoscopeSecChange = (sec) => {
    setHoroscopeSec(sec.target.value);
  };

  function handleChange(event) {
    setHoroscopesTime(event.target.value);
    console.log(event.target.value);
  }

  const horoscopeGenderChange = (gender) => {
    setHoroscopeGender(gender.target.value);
  };

  const callHoroscopeAPI = (name, gender, day, month, year, hour, min, sec,lat=context.requestParamsHoroscope.latitude,long=context.requestParamsHoroscope.longitude) => {
    let ApiInstant = new Api();
    ApiInstant.fetchUserHoroscope(
      name,
      genderMapper[gender],
      day,
      month,
      year,
      hour,
      min,
      sec,
      lat,
      long,
      context.requestParamsHoroscope.offset,
      context.requestParamsHoroscope.name,
      context.requestParamsHoroscope.state,
      context.requestParamsHoroscope.country,
      context.requestParamsHoroscope.reference_longitude_for_timezone,
      context.requestParamsHoroscope.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        if (result) {
          if (result.data.message === "Token expired") {
            context.handleShow(`/horoscope/${props.view}`);
            callHoroscopeAPI(
              name,
              gender,
              day,
              month,
              year,
              hour,
              min,
              sec,
              lat,
              long,
              context.requestParamsHoroscope.offset,
              context.requestParamsHoroscope.name,
              context.requestParamsHoroscope.state,
              context.requestParamsHoroscope.country,
              context.requestParamsHoroscope.reference_longitude_for_timezone,
              context.requestParamsHoroscope.timezone,
              localStorage.getItem("omp_token")
            );
          } else {
            context.setHoroscopeData(result.data);
            callAnalyzeHoroscope(name, gender, day, month, year, hour, min, sec,lat,long);
            context.resetLoading(false);
            context.handleHoroscopeForm(false);
            props.history.push(`/horoscope/${props.view}`);
          }
        }
      })
      .catch((e) => {
        // console.log(e);
        setPlaceError("Horoscope API issue");
        context.resetLoading(false);
      });
  };

  const callAnalyzeHoroscope = (name, gender, day, month, year, hour, min, sec,lat=context.requestParamsHoroscope.latitude,long=context.requestParamsHoroscope.longitude) => {

    let ApiYoga = new Api();
    ApiYoga.fetchAnalyzeHoroscopeAPI(
      name,
      genderMapper[gender],
      day,
      month,
      year,
      hour,
      min,
      sec,
      lat,
      long,
      context.requestParamsHoroscope.offset,
      context.requestParamsHoroscope.name,
      context.requestParamsHoroscope.state,
      context.requestParamsHoroscope.country,
      context.requestParamsHoroscope.reference_longitude_for_timezone,
      context.requestParamsHoroscope.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        if (result.data) {
          window.localStorage.setItem("requestYogaAnalyzer", JSON.stringify(result.data));
          context.setYogaAnalyzerData(result.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setPlaceError("Yoga Analyzer API issue");
        context.resetLoading(false);
      });
  };

  const alertClick = async () => {
    setPlaceError(null);
    context.resetLoading(true);
    let name = document.getElementById("name").value;
    let latitude = horoscopesLat !== null ? horoscopesLat : context.requestParamsHoroscope.latitude;
    let longitude = horoscopesLong !== null ? horoscopesLong : context.requestParamsHoroscope.longitude;
    
    // let country = document.getElementById("auto_completecountry").value;
      // let cityAndStateRaw = document.getElementById("auto_complete1").value;
    
    
    let country=context.horoscopeCountry;
   let cityAndStateRaw= `${JSON.parse(localStorage.getItem("requestParamsHoroscope")).name}, ${JSON.parse(localStorage.getItem("requestParamsHoroscope")).state}`;
 
    console.log(cityAndStateRaw);
    let gettext_dob = document.getElementById("textDOB").value;
  
    setHoroscopesDOB(gettext_dob);
    // console.log("hiiiii- " + gettext_dob);
    let parts_of_DOB = gettext_dob.split(".");
    //  console.log(parts_of_DOB[0]);
    //  console.log(parts_of_DOB[1]);
    //  console.log(parts_of_DOB[2]);

    horoscopeCountryChange(country);
    horoscopeCityStateChange(cityAndStateRaw);
    context.setCityAndState(cityAndStateRaw, "Horoscope");
    let cityAndState =
      cityAndStateRaw !== "" && cityAndStateRaw.includes(",")
        ? cityAndStateRaw.split(",")
        : null;
    // let date =
    //   horoscopeDate ||
    //   moment(context.horoscopeData.date_of_birth, "DD-MM-YYYY");
    let gender = null;
    if (horoscopeGender === null) {
      if (Object.keys(context.horoscopeData).length > 0) {
        gender = context.horoscopeData.basic_details.gender;
      } else {
        gender = "Male";
      }
    } else {
      gender = t(horoscopeGender);
    }
    
    let year = parts_of_DOB[2];
    // let year =
    //   horoscopeYear === null
    //     ? context.horoscopeData.date_of_birth
    //       ? processDates(context.horoscopeData.date_of_birth).getFullYear()
    //       : null
    //     : horoscopeYear;
    
    let month= parts_of_DOB[1];

    // let month =
    //   horoscopeMonth === null
    //     ? context.horoscopeData.date_of_birth
    //       ? processDates(context.horoscopeData.date_of_birth).getMonth() + 1
    //       : null
    //     : horoscopeMonth;
    month = parseInt(month);
    let day = parts_of_DOB[0];
    // let day =
    //   horoscopeDay === null
    //     ? context.horoscopeData.date_of_birth
    //       ? processDates(context.horoscopeData.date_of_birth).getDate()
    //       : null
    //     : horoscopeDay;
    let hour =
      horoscopeHour === null
        ? context.horoscopeData.time_of_birth
          ? timeFormatter("hour", context.horoscopeData.time_of_birth)
          : ""
        : horoscopeHour;
    let min =
      horoscopeMin === null
        ? context.horoscopeData.time_of_birth
          ? timeFormatter("min", context.horoscopeData.time_of_birth)
          : ""
        : horoscopeMin;
    let sec =
      horoscopeSec === null
        ? context.horoscopeData.time_of_birth
          ? timeFormatter("sec", context.horoscopeData.time_of_birth)
          : ""
        : horoscopeSec;


    const regex_four_digit =/^\d{4}$/g;
    console.log(hour,min,sec)
    let dayMonths31 = [1, 3, 5, 7, 8, 10, 12];
    if (year === null || month === null || day === null || day === '__' || month === '__' || year === '____' || !year.match(regex_four_digit) ) {
      setPlaceError("Please Check Date");
      
      context.resetLoading(false);
    } else if (
      (day === 31 && dayMonths31.indexOf(month) === -1) ||
      (month === 2 && leapYear(year) && day > 29) ||
      (month === 2 && !leapYear(year) && day > 28)
    ) {
      setPlaceError("Please Check Month and Date Combination");
      context.resetLoading(false);
    } else if (!hour || !min || !sec) {
      setPlaceError("Please Check Time");
      context.resetLoading(false);
    } else if (gender === null) {
      setPlaceError("Please Check Gender");
      context.resetLoading(false);
    } else if (name === "") {
      setPlaceError("Please Check Name");
      context.resetLoading(false);
    } else if (cityAndStateRaw === "") {
      setPlaceError("Please Check City Details");
      context.resetLoading(false);
    } else if (country === null) {
      setPlaceError("Please Select Country");
      context.resetLoading(false);
    } else if (horoscopePlaceError !== null) {
      setPlaceError(horoscopePlaceError);
      context.resetLoading(false);
    } else {
      console.log('inside----');
      
      callHoroscopeAPI(name, gender, day, month, year, hour, min, sec,latitude,longitude);
    }
  };

  const handleFormClose = () => {
    if (Object.keys(context.horoscopeData).length === 0) {
      props.history.push(`/dashboard/`);
      setPlaceError(null);
    } else {
      props.handleClose(false);
      setPlaceError(null);
    }
  };

  // console.log(context.horoscopeCityState);
  return (
    <>
      <div className="">
        <Modal
          animation={false}
          size="md"
          dialogClassName={"primaryModal"}
          show={props.show}
          onHide={handleFormClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Provide Birth Details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
            
              <div className="d-flex flex-column ">
              
                <div className="form-group mb-2">
                  <label htmlFor="example-input-small">{t("Name")}:</label>
                  
                    <input
                      autoComplete="off"
                      type="text"
                      id="name"
                      className="form-control"
                      value={
                        horoscopeName === null
                          ? Object.keys(context.horoscopeData).length > 0
                            ? context.horoscopeData.native_name
                            : ""
                          : horoscopeName
                      }
                      onChange={(name) => horoscopeNameChange(name)}
                      style={{ width: "70%",textTransform:"capitalize" }}
                    />
                  
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="example-input-small">
                    {t("Country of Birth")}:
                  </label>
                  <CountryAutocomplete
                    mode={"Horoscope"}
                    autoComplete="off"
                   
                    defaultValue={
                      horoscopeCountry !== null
                        ? horoscopeCountry
                        : context.horoscopeCountry
                    }
                    resetInputText={props.resetInputText}
                    handleChange={props.handleAutoCompleterChange}
                    suggestions={props.cities_name}
                    // id="autocomplete"
                    city={
                      horoscopeCountry !== null
                        ? horoscopeCountry
                        : context.horoscopeCountry
                    }
                  />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="example-input-small">
                    {t("City of Birth")}:
                  </label>
                  <Autocomplete
                    autoComplete="off"
                    mode={"Horoscope"}
                    defaultValue={horoscopeCityState !== null? horoscopeCityState: context.horoscopeCityState}
                    resetInputText={props.resetInputText}
                    handleChange={props.handleAutoCompleterChange}
                    suggestions={props.cities_name}
                    city={horoscopeCityState !== null? horoscopeCityState: context.horoscopeCityState}
                    country={context.horoscopeCountry}
                    horoscopePlaceErrorChange={horoscopePlaceErrorChange}
                  />
                </div>
                {(horoscopeCityState ||context.horoscopeCityState)?<>
                  <div>
                <LatLongInput
                initialLat={horoscopesLat !== null ? horoscopesLat : context.requestParamsHoroscope.latitude}
                initialLong={horoscopesLong !== null ? horoscopesLong : context.requestParamsHoroscope.longitude}
                onLatChange={horoscopeLatChange}
                onLongChange={horoscopeLongChange}
                citySelected={true} // Set this based on whether a city is selected
                t={t}/>
               </div>
                </>:<></>}
               
                <label htmlFor="example-input-small mb-2">
                  {t("Select Date of Birth")}:  dd . mm . yyyy
                </label>
                {/* <div className="d-flex">
                  <div className="mb-1" style={{ display: "flex" }}>
                    <select
                      className="form-control"
                      placeholder="Month"
                      style={{ width: "140px" }}
                      value={
                        horoscopeMonth === null
                          ? context.horoscopeData.date_of_birth
                            ? processDates(
                              context.horoscopeData.date_of_birth
                            ).getMonth() + 1
                            : ""
                          : horoscopeMonth
                      }
                      onChange={(month) => horoscopeMonthChange(month)}
                    >
                      <option value="" disabled>
                        {t("Month")}
                      </option>
                      <option value={1}>{t("January")}</option>
                      <option value={2}>{t("February")}</option>
                      <option value={3}>{t("March")}</option>
                      <option value={4}>{t("April")}</option>
                      <option value={5}>{t("May")}</option>
                      <option value={6}>{t("June")}</option>
                      <option value={7}>{t("July")}</option>
                      <option value={8}>{t("August")}</option>
                      <option value={9}>{t("September")}</option>
                      <option value={10}>{t("October")}</option>
                      <option value={11}>{t("November")}</option>
                      <option value={12}>{t("December")}</option>
                    </select>
                    <select
                      className="form-control"
                      style={{ width: "80px" }}
                      value={
                        horoscopeDay === null
                          ? context.horoscopeData.date_of_birth
                            ? processDates(
                              context.horoscopeData.date_of_birth
                            ).getDate()
                            : ""
                          : horoscopeDay
                      }
                      onChange={(day) => horoscopeDayChange(day)}
                    >
                      <option value="" disabled>
                        {t("Day")}
                      </option>
                      {dayArray.map((item, index) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ width: "90px" }}
                      className="form-control"
                      value={
                        horoscopeYear === null
                          ? context.horoscopeData.date_of_birth
                            ? processDates(
                              context.horoscopeData.date_of_birth
                            ).getFullYear()
                            : ""
                          : horoscopeYear
                      }
                      onChange={(year) => horoscopeYearChange(year)}
                    >
                      <option value="" disabled>
                        {t("Year")}
                      </option>
                      {yearArray.map((item, index) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                <div className="d-flex">
                  <div className="mb-1" style={{ display: "flex" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroupPrepend">
                        DOB
                      </span>
                    </div>
                    <IMaskInput
                      id="textDOB"
                      style={{ fontSize: "0.9rem", "letter-spacing": "5px" }}
                      mask={Date}
                      pattern={"d.m`.`Y"}
                      lazy={false}
                      min={new Date(1900, 0, 1)}
                      max={new Date(2100, 0, 1)}
                      onAccept={(value, mask) => setHoroscopesDOB(value)}
                      placeholder="DD.MM.YYYY"
                      className="form-control"
                      value={
                        horoscopesDOB == null
                          ? context.horoscopeData.date_of_birth
                          : horoscopesDOB
                      }
                    />
                   </div>
                </div>
                <label htmlFor="example-input-small mb-2">
                  {t("Select Time of Birth")}: hh:mm:ss
                </label>
                <div className="d-flex">
                  <div className="mb-1" style={{ display: "flex" }}>
                    {/* <select
                      className="form-control"
                      placeholder="Hour"
                      style={{ width: "32%" }}
                      value={
                        horoscopeHour === null
                          ? context.horoscopeData.time_of_birth
                            ? timeFormatter(
                              "hour",
                              context.horoscopeData.time_of_birth
                            )
                            : ""
                          : horoscopeHour
                      }
                      onChange={(hour) => horoscopeHourChange(hour)}
                    >
                      <option value="" disabled>
                        {t("Hour")}
                      </option>
                      {hourArray.map((item, index) => {
                        return (
                          <option key={item} value={item}>
                            {item < 10 ? 0 + String(item) : item}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      className="form-control"
                      style={{ width: "33%" }}
                      value={
                        horoscopeMin === null
                          ? context.horoscopeData.time_of_birth
                            ? timeFormatter(
                              "min",
                              context.horoscopeData.time_of_birth
                            )
                            : ""
                          : horoscopeMin
                      }
                      onChange={(min) => horoscopeMinChange(min)}
                    >
                      <option value="" disabled>
                        {t("Minutes")}
                      </option>
                      {minSecArray.map((item, index) => {
                        return (
                          <option key={item} value={item}>
                            {item < 10 ? 0 + String(item) : item}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      style={{ width: "33%" }}
                      className="form-control"
                      value={
                        horoscopeSec === null
                          ? context.horoscopeData.time_of_birth
                            ? timeFormatter(
                              "sec",
                              context.horoscopeData.time_of_birth
                            )
                            : ""
                          : horoscopeSec
                      }
                      onChange={(sec) => horoscopeSecChange(sec)}
                    >
                      <option value="" disabled>
                        {t("Seconds")}
                      </option>
                      {minSecArray.map((item, index) => {
                        return (
                          <option key={item} value={item}>
                            {item < 10 ? 0 + String(item) : item}
                          </option>
                        );
                      })}
                    </select> */}

                    {/* <input id="appt-time" type="time" name="appt-time" step="2"
                     className="form-control"
                     value={horoscopesTime == null ? context.horoscopeData.time_of_birth :horoscopesTime}
                      onChange={handleChange} style={{"font-size":"20px","width":"200px"}}
                    
                     /> */}
                     
                    <IMaskInput
                      style={{ width: "20%", fontSize: "0.9rem" }}
                      mask={Number}
                      min={0}
                      max={23}
                      value={
                        horoscopeHour === null ? context.horoscopeData.time_of_birth
                            ? timeFormatter(
                              "hour",
                              context.horoscopeData.time_of_birth
                            )
                            : ""
                          : horoscopeHour
                      }
                      onAccept={(value, mask) => setHoroscopeHour(value)}
                      placeholder="hh"
                      className="form-control"
                    ></IMaskInput>

                    <span
                      style={{
                        "font-size": "0.9rem",
                        padding: "6px",
                        "font-weight": "600",
                      }}
                    >
                      :
                    </span>

                    <IMaskInput
                      style={{ width: "20%", fontSize: "0.9rem" }}
                      mask={Number}
                      min={0}
                      max={59}
                      value={
                        horoscopeMin === null
                          ? context.horoscopeData.time_of_birth
                            ? timeFormatter(
                              "min",
                              context.horoscopeData.time_of_birth
                            )
                            : ""
                          : horoscopeMin
                      }
                      onAccept={(value, mask) => setHoroscopeMin(value)}
                      placeholder="mm"
                      className="form-control"
                    ></IMaskInput>
                    <span
                      style={{
                        "font-size": "0.9rem",
                        padding: "6px",
                        "font-weight": "600",
                      }}
                    >
                      :
                    </span>
                    <IMaskInput
                      style={{ width: "20%", fontSize: "0.9rem" }}
                      mask={Number}
                      min={0}
                      max={59}
                      value={
                        horoscopeSec === null
                          ? context.horoscopeData.time_of_birth
                            ? timeFormatter(
                              "sec",
                              context.horoscopeData.time_of_birth
                            )
                            : ""
                          : horoscopeSec
                      }
                      onAccept={(value, mask) => setHoroscopeSec(value)}
                      placeholder="ss"
                      className="form-control"
                    ></IMaskInput>
                  </div>
                </div>
                <label htmlFor="example-input-small mb-2">
                  {t("Select Gender")}:
                </label>
                <div className="d-flex">
                  <div className="mb-1">
                    <FormControl className={classes.formControl}>
                      <Select
                        style={{
                          height: "2.5em",
                          width: "140px",
                          border: "1px solid #ced4da",
                          marginBottom: "10px",
                        }}
                        native
                        value={
                          horoscopeGender === null
                            ? Object.keys(context.horoscopeData).length > 0 &&
                              typeof context.horoscopeData !== "undefined"
                              ? context.horoscopeData.basic_details.gender
                              : "Male"
                            : horoscopeGender
                        }
                        onChange={(gender) => horoscopeGenderChange(gender)}
                        inputProps={{
                          name: "age",
                          id: "age-native-simple",
                        }}
                      >
                        <option value={"Male"}>{t("Male")}</option>
                        <option value={"Female"}>{t("Female")}</option>
                      </Select>
                    </FormControl>
                    {placeError && <p className="form-error">{placeError}</p>}
                  </div>
                </div>
              
                <button
                  type="submit"
                  className="btn btn-primary font-16"
                  style={{ width: "300px" }}
                  onClick={alertClick}
                  disabled={context.IsLoading}
                >
                  {context.IsLoading ? (
                    <span>
                      {t("Getting Data")}{" "}
                      <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                    </span>
                  ) : (
                    t("Get Data")
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* <Login
        page={context.currentPage}
        show={context.loginModalShow}
        handleClose={context.closeLoginModal}
      /> */}
    </>
  );
};

export default withRouter(HoroscopeForm);
